import { Link } from "react-router-dom";
import { useState } from "react";

const Pricing1 = () => {
  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);
  return (
    <section className="pricing-area pb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-50">
              <h2
                className="title title-animation wow fadeInUp founding-team-title"
                data-wow-delay=".2s"
              >
                Founding Team
              </h2>
            </div>
          </div>
        </div>
        <div className="pricing-item-wrap">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10">
              <div className="pricing-item wow fadeInLeft" data-wow-delay=".2s">
                <img src="./zara.png" style={{marginBottom: '40px'}} />
                <h4 class="text-center">Zara Rahimi</h4>
                <div className="text-center">
                  <p>Debate & Leadership</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10">
              <div className="pricing-item wow fadeInLeft" data-wow-delay=".2s">
                <img src="./amir.png" style={{marginBottom: '40px'}} />
                <h4 class="text-center">Amir Rahimi</h4>
                <div className="text-center">
                  <p>Math & Coding</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Pricing1;
