import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect'

const Banner1 = () => {
    return (
        <section className="banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-content ta-animated-headline">
                                <h2 className="title ah-headline wow fadeInUp" data-wow-delay=".2s">
                                    <span className="grassroots-text">Applying a grassroots mentoring approach, we empower youth to realize their full potential and forge meaningful connections that will bolster their academic journeys.</span>
                                </h2>
                                <p className="wow fadeInUp white-text" data-wow-delay=".4s"><strong>We are the children of first-generation Afghan immigrants</strong> and we've founded Dreamforge, a nonprofit organization dedicated to fostering a supportive community through engaging and interactive mentoring sessions tailored to key developmental areas. Our doors are open to all youths aged 6 to 11, with a special emphasis on recently resettled Afghan refugees in the United States.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

    )
}

export default Banner1;