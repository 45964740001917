import { Link } from "react-router-dom";
import Slider from "react-slick";
const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  arrows: false,
  slidesToShow: 6,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const Footer1 = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          {/* brand-area */}
          {/* <div className="brand-area">
            <div className="brand-wrap">
              <h6 className="title">
                Trusted by <span>10,000+ marketing</span> teams
              </h6>
              <Slider {...settings} className="row brand-active">
                <div className="col">
                  <div className="brand-item">
                    <img src="/assets/img/brand/brand_img01.png" alt="" />
                  </div>
                </div>
                <div className="col">
                  <div className="brand-item">
                    <img src="/assets/img/brand/brand_img02.png" alt="" />
                  </div>
                </div>
                <div className="col">
                  <div className="brand-item">
                    <img src="/assets/img/brand/brand_img03.png" alt="" />
                  </div>
                </div>
                <div className="col">
                  <div className="brand-item">
                    <img src="/assets/img/brand/brand_img04.png" alt="" />
                  </div>
                </div>
                <div className="col">
                  <div className="brand-item">
                    <img src="/assets/img/brand/brand_img05.png" alt="" />
                  </div>
                </div>
                <div className="col">
                  <div className="brand-item">
                    <img src="/assets/img/brand/brand_img06.png" alt="" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
           */}{/* brand-area-end */}
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="footer-widget st3">
                  <h4 className="fw-title">Contact Us</h4>
                  <div className="footer-contact">
                    {/*
                    <Link to="tel:0123456789" className="phone">
                      (571) 888 5169
                    </Link> 
                    */}
                    <Link to="mailto:zara@dreamforge.info" className="email">
                    sonya.rahmani@gmail.com
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </footer>
  );
};
export default Footer1;
