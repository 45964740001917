const Counter1 = () => {
  return (
    <section className="counter-area pt-0 pb-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
          </div>
        </div>
      </div>
    </section>
  );
};
export default Counter1;
