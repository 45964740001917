import HomeOne from "./HomeOne";
import HoweTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import About from "./About";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import Faq from "./Faq";
import Help from "./Help";
import Login from "./Login";
import Work from "./Work";
import Error from "./Error";
import Job from "./Job";
import JobDetails from "./JobDetails";


// removed paths here
const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/contact', component: <Contact />},
  { path: '*', component: <Error />},



]

export default routes;